@media print {
  /*@page {*/
  /*  margin: 1in; !* 1 inch margin for all sides *!*/
  /*}*/

  @page {
    margin-top: 0.5in;
    margin-bottom: 0.75in;
    margin-left: 1.25in;
    margin-right: 1in;
  }
}
